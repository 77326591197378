<template>
  <div>
    <div class="page-body">
      <title-bar title="角色维护">
        <el-button type="default" @click="$router.back()" icon="fa fa-angle-left" :loading="loading">
          返回
        </el-button>
      </title-bar>
      <el-form ref="editForm" :model="record" :rules="rules" label-width="120px" style="width: 600px;margin: auto">
        <el-form-item label="角色编码" prop="roleCode">
          <el-input type="text" v-model="record.roleCode" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="角色名称" prop="roleName">
          <el-input type="text" v-model="record.roleName" maxlength="50"></el-input>
        </el-form-item>
        <div class="button-container">
          <el-button type="primary" @click="save" :loading="loading">
            <i class="fa fa-save"></i>
            保存
          </el-button>
          <el-button type="default" @click="$router.back()" :loading="loading">
            <i class="fa fa-chevron-left"></i>
            取消
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";

export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      roleId: "",
      record: {
        sysRole: false
      },
      rules: {
        roleCode: [{required:true,message:'角色编码不能为空',trigger:'blur'}],
        roleName: [{required:true,message:'角色名称不能为空',trigger:'blur'}]
      }
    }
  },
  created() {
    this.roleId = this.$route.query.roleId || '';
    if(!this.roleId) {
      return;
    }
    this.loading = true;
    this.postForm("/role/getById", {
      roleId: this.roleId
    }).then(dr => {
      this.record = dr.bean || {};
    }).catch( e => {
      this.$message.error(e.msg);
    }).finally(()=>{
      this.loading = false;
    })
  },
  methods: {
      save() {
        this.$refs["editForm"].validate(ok => {
          if(!ok) {
            return;
          }
          this.loading = true;
          this.postForm("/role/save",this.record, true).then(dr => {
            this.record = dr.bean;
            this.$router.back();
          }).finally(dr => {
            this.loading = false;
          });
        });
      }
  }
}
</script>

<style scoped lang="scss">
</style>
